import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'hds-react';
import ExternalLink from '../../../../components/ExternalLink';
import InternalLink from '../../../../components/InternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/breakpoints",
  "title": "Breakpoint tokens - Usage",
  "navTitle": "Breakpoints"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS includes tokenized values for both breakpoints and a maximum container width. Breakpoint tokens can be also used alongside `}<InternalLink size="M" href="/foundation/guidelines/grid" mdxType="InternalLink">{`HDS grid guidelines`}</InternalLink>{` in order to create consistent designs and implementations.`}</p>
    <p><strong parentName="p">{`HDS offers a Container component which follows breakpoint and container width tokens automatically.`}</strong>{` It is recommended to use it if possible. For more info and examples, see `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-container--example" mdxType="Link">{`Container - React documentation`}</Link>{` and `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-container--default" mdxType="Link">{`Container - Core documentation`}</Link>{`.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Always use token of the same level for both the breakpoint and container width. This ensures consistency across all services using HDS breakpoint tokens. Read more about this in `}<a parentName="li" {...{
          "href": "#using-breakpoint-tokens"
        }}>{`the breakpoint usage section.`}</a></li>
      <li parentName="ul">{`Aim to provide all breakpoints listed in HDS tokens in your service. This way you ensure your service is usable with every common screen width.`}</li>
      <li parentName="ul">{`For best results, test your designs and implementations at the edge of each HDS breakpoint.`}</li>
      <li parentName="ul">{`HDS uses a simple method to scale content when the screen size changes. Below the `}<inlineCode parentName="li">{`--container-width-xl`}</inlineCode>{` the content container always takes all available space.`}
        <ul parentName="li">
          <li parentName="ul">{`It is recommended to use scaling methods HDS suggests for each breakpoint (`}<InternalLink size="M" href="/foundation/design-tokens/breakpoints/tokens#tokens" mdxType="InternalLink">{`see Breakpoints token table`}</InternalLink>{`). However, it is allowed to use different scaling methods if it works better for the service.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h3" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Carefully test your implementation at different breakpoints - especially at the edge values of a breakpoint.`}</li>
      <li parentName="ul">{`Pay extra attention how your service behaves if browser zoom functionality is used. Read more about the `}<ExternalLink href="https://www.w3.org/WAI/WCAG21/Understanding/reflow.html" mdxType="ExternalLink">{`WCAG requirements considering reflow`}</ExternalLink>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "using-breakpoint-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Using breakpoint tokens`}<a parentName="h3" {...{
        "href": "#using-breakpoint-tokens",
        "aria-label": "using breakpoint tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The breakpoint tokens are divided into two sets;`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breakpoint tokens`}</strong>{` define exact widths (in pixels) where the next breakpoint comes into effect.`}</li>
      <li parentName="ul"><strong parentName="li">{`Container width token`}</strong>{` defines maximum container width.`}</li>
    </ul>
    <p>{`When breakpoints change the left and right padding of the container changes. It is recommended to use the provided padding values because they match with `}<InternalLink href="/components/navigation" mdxType="InternalLink">{`HDS Navigation`}</InternalLink>{` and `}<InternalLink href="/components/footer" mdxType="InternalLink">{`HDS Footer`}</InternalLink>{` paddings. You can find these paddings on the `}<InternalLink href="/foundation/design-tokens/breakpoints/tokens" mdxType="InternalLink">{`HDS Breakpoints tokens tab.`}</InternalLink></p>
    <p>{`The maximum content container width is always `}<inlineCode parentName="p">{`--container-width-xl`}</inlineCode>{` (1200px). When the viewport width is below this value, the content container always takes all available space.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      